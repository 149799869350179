import { Config } from './types/config'
import { createRoot, Root } from 'react-dom/client'
import pkgVersion from './version'
import { VMWidgetQRCode } from './qrCodeWidget'

import { isBrowserSupported, isUPCSupported } from '@utils/helpers'
import { reset } from '@utils/reset'
import { warmUp } from '@utils/warmUp'
import { deprecatedClearPicture } from '@utils/deprecatedClearPicture'

interface IVMWidgetApp {
  config: Config
  init: () => void
  unmount: () => Promise<void>
  reset: () => Promise<void>
}

class VMWidgetApp implements IVMWidgetApp {
  public config: Config
  private root: Root | null
  constructor(config: Config) {
    this.config = config
    const container = document.querySelector(this.config.selector)
    this.root = container ? createRoot(container) : null
  }

  async init() {
    if (!this.config) {
      throw new Error('Config is not defined')
    }

    if (!this.root) {
      throw new Error(
        `You requested Virtual mirror to render inside the element with the selector ${this.config.selector}, but there is no such element in the document. Check the "selector" parameter in Virtual mirror initialization or your DOM.`
      )
    }

    const { VmWidgetUi } = await import('./VmWidgetUi')
    const { AppLoader } = await import('@components/Loader')
    const { isPrivacyCookieAccepted } = await import('@utils/cookies')

    this.root.render(
      <>
        <AppLoader container={this.config.selector} isDisabled={isPrivacyCookieAccepted()} />
        <VmWidgetUi config={this.config} />
      </>
    )
  }

  async unmount() {
    if (this.root) {
      try {
        const { unmount } = await import('@utils/unmount')
        await unmount()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      this.root.unmount()
    }
  }

  // to be removed with VMC-421
  async reset() {
    if (this.root) {
      // eslint-disable-next-line no-console
      console.warn(
        // eslint-disable-next-line quotes
        `This method will be removed in the next major release,
        please use namespace\' method instead: vmmv.reset()`
      )
      try {
        reset()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  }

  static new(config: Config) {
    return new VMWidgetApp(config)
  }

  static version() {
    return pkgVersion
  }
}

export {
  VMWidgetApp,
  VMWidgetQRCode,
  deprecatedClearPicture as clearPictureVideoIds,
  isUPCSupported,
  isBrowserSupported,
  reset,
  warmUp,
}
