import { Resolution, UpcAvailability } from '@customTypes/upcAvailability'

export const isUPCSupported = async (
  upcs: string[],
  licenseKey: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  glassesEnv: any = 'PROD'
) => {
  const upcList = upcs.filter(x => !!x)

  const upcAvailability: UpcAvailability = {}

  // use dynamic import
  const { VirtualMirrorCatalogue } = await import('@luxottica/virtual-mirror')

  const catalogueUpcs = await VirtualMirrorCatalogue.build({
    key: licenseKey,
    catalogueEnvironment: glassesEnv,
  }).isUpcSupportedImproved(...upcList)

  const getResolution = (resolution: { '256': boolean; '512': boolean }) => {
    return [resolution[256] ? 'PX_256' : undefined, resolution[512] ? 'PX_512' : undefined].filter(
      x => !!x
    ) as Resolution[]
  }

  for (const upcKey of upcList) {
    let duplicateUpcItems = catalogueUpcs.filter(x => x.getUpc() === upcKey)

    if (duplicateUpcItems.length > 0) {
      const isThereIsAvailable = duplicateUpcItems.some(x => x.isAvailable())

      if (isThereIsAvailable) {
        duplicateUpcItems = duplicateUpcItems.filter(x => x.isAvailable())
      }

      // get first UPC from duplicated items, it will filter if there is available
      const upcCatalog = duplicateUpcItems[0]

      upcAvailability[upcCatalog.getUpc()] = {
        available: upcCatalog.isAvailable(),
        resolutions: getResolution(upcCatalog.available),
        transitions: upcCatalog.isTransition(),
        transitionsResolutions: getResolution(upcCatalog.transitions),
        lods: upcCatalog.lods,
      }
    }
  }

  return upcAvailability
}

export const isBrowserSupported = async () => {
  const { VirtualMirror } = await import('@luxottica/virtual-mirror')

  return await VirtualMirror.isBrowserSupported()
}
